import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import TestimonialsStyleOne from "../components/Testimonials/TestimonialsStyleOne";
import TestimonialsStyleTwo from "../components/Testimonials/TestimonialsStyleTwo";
import { Helmet } from "react-helmet";
const Testimonials = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Testimonials| HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Testimonials" homePageText="Home" homePageUrl="/" activePageText="Testimonials" />
      <TestimonialsStyleOne />
      <TestimonialsStyleTwo />
      <Footer />
    </Layout>
  );
};

export default Testimonials;
